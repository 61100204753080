import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Img from 'gatsby-image';
import { graphql, Link } from 'gatsby';
import HeroCarousel from '../components/carousel/hero-carousel';

const Exchanges1031 = ({ data }) => {
    const { heroImage, hyve1, hyve2, hyve3, jade, west, entrada, farms, banner } = data;

    return (
        <Layout>
            <SEO title="Delaware Statutory Trusts" />
            <div className="page-1031">
                <section className="page-1031-hero no-container" data-component="hero">
                    {/* <Img
                        fluid={heroImage.childImageSharp.fluid}
                        alt={''}
                        className="page-1031-hero__background"
                    /> */}
                    <div className="container">
                        <h1 className="heading1">Delaware Statutory Trusts</h1>
                        <Link className="btn btn-primary" to="/contact">
                            Book a consultation
                        </Link>
                    </div>
                </section>

                <section className="page-1031-dst no-container">
                    <div className="page-1031-dst__content container">
                        <div>
                            <h2>Delaware Statutory Trusts</h2>
                            <p>Passively Exchange into Institutional-Quality Real Estate</p>
                            <p>
                                A DST is an entity that is considered a “like kind” replacement
                                property for the purposes of completing a 1031 exchange. By
                                investing in a DST, e.g. ICG1031 Tempe DST, investors are eligible
                                to <strong>defer their capital gains via a 1031 exchange</strong>.
                                Further, while DSTs are illiquid investments, investors can{' '}
                                <strong>1031 exchange out of the DST</strong> once we elect the sell
                                the DST. We also will perform a cost segregation study upon
                                takeover, aiming to{' '}
                                <strong>offset investor cash flow with depreciation</strong>. Please
                                consult your tax professionals for applicability to your specific
                                situation.
                            </p>
                        </div>
                        <div className="page-1031-dst__card">
                            Institutional-level reporting and management.
                            <br />
                            Limited liability
                            <br />
                            Total passivity.
                            <div className="page-1031-dst__card-quote">
                                You Receive <strong>100%</strong> of Net Sales Proceeds,
                                Depreciation, and Net Cash Flow
                            </div>
                        </div>
                    </div>
                    <div className="page-1031-dst__steps-container">
                        <ol className="page-1031-dst__steps container">
                            <li>Step 1: Sell Your Property</li>
                            <li>Step 2: Identify IDEAL's DST as replacement property</li>
                            <li>Step 3: Close on IDEAL's DST within days</li>
                            <li>
                                Step 4: Receive monthly cash flow and quarterly investor updates
                            </li>
                        </ol>
                    </div>
                </section>
                <section className="page-1031-case-study">
                    <div className="page-1031-case-study__content">
                        <div>
                            <h2>DST Case Study</h2>
                            <p>The Hyve - Tempe, AZ</p>

                            <h3>Key stats:</h3>
                            <ul>
                                <li>296 units</li>
                                <li>Class A Apartment</li>
                                <li>2015 construction</li>
                                <li>$47,851,000 equity raised</li>
                                <li>$50,000 minimum investment</li>
                                <li>1031 eligible</li>
                                <li>Cash Flow Distributed Monthly</li>
                                <li>Depreciation Allocated Pro Rata to Investors</li>
                            </ul>
                        </div>
                        <div className="page-1031-case-study__carousel">
                            <HeroCarousel
                                items={[hyve1, hyve2, hyve3].map((img) => {
                                    return {
                                        image: {
                                            imageFile: {
                                                ...img,
                                            },
                                        },
                                    };
                                })}
                            />
                        </div>
                    </div>
                    <p>
                        IDEAL acquired The Hyve, a 296-unit Class A apartment community located in
                        Tempe, Arizona. Built in 2015, The Hyve is the value luxury option for
                        residents in the most dynamic market in the Phoenix metro. Tempe hosts major
                        corporate employers such as State Farm, Microsoft, Amazon, Wells Fargo,
                        KPMG, Honeywell, and Arizona State University—the largest public university
                        in the country. Furthermore, The Hyve is adjacent to the Novus Innovation
                        Corridor, the largest Class A office development in the Western US that is
                        projected to bring 25,000 new jobs to the submarket. The Hyve is well
                        situated for long-term growth.
                    </p>
                    <p>
                        Investors receive monthly cash flow, limited liability, passivity,
                        depreciation, and the ability to 1031 exchange into and out of the fund.
                    </p>
                    <p>
                        Investors get 100% of the net cash flow, net sales proceeds, and
                        depreciation benefits. Best of all, investors can defer capital gains
                        through a 1031 exchange into the fund and after we sell The Hyve.
                    </p>
                </section>

                <section className="page-1031-contact">
                    <h2>Contact Us</h2>
                    <div className="page-1031-contact__grid">
                        <div>
                            <h3>Mailing Address</h3>
                            <p>3128 Willow Ave., Suite 101 Clovis, CA 93612</p>
                        </div>
                        <div>
                            <h3>Email Address</h3>
                            <p>
                                <a href="mailto:kevin@idealcapgroup.com">kevin@idealcapgroup.com</a>
                            </p>
                        </div>

                        <div>
                            <h3>Phone Number</h3>
                            <p>
                                <a href="tel:559-347-6980">(559) 347-6980</a>
                            </p>
                        </div>
                    </div>
                </section>
            </div>
        </Layout>
    );
};

export default Exchanges1031;

export const query = graphql`
    query {
        hyve1: file(relativePath: { in: "1031-hyve-1-optimized.jpeg" }) {
            childImageSharp {
                fluid(maxWidth: 1280, maxHeight: 850) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        hyve2: file(relativePath: { in: "1031-hyve-2-optimized.jpeg" }) {
            childImageSharp {
                fluid(maxWidth: 1280, maxHeight: 850) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        hyve3: file(relativePath: { in: "1031-hyve-3-optimized.jpeg" }) {
            childImageSharp {
                fluid(maxWidth: 1280, maxHeight: 850) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        jade: file(relativePath: { in: "1031-jade-optimized.jpeg" }) {
            childImageSharp {
                fluid(maxWidth: 1280, maxHeight: 850) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        west: file(relativePath: { in: "1031-west-optimized.jpeg" }) {
            childImageSharp {
                fluid(maxWidth: 1280, maxHeight: 850) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        farms: file(relativePath: { in: "1031-farms-optimized.jpeg" }) {
            childImageSharp {
                fluid(maxWidth: 1280, maxHeight: 850) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        entrada: file(relativePath: { in: "1031-entrada-optimized.jpeg" }) {
            childImageSharp {
                fluid(maxWidth: 1280, maxHeight: 850) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        heroImage: file(relativePath: { in: "1031-banner.webp" }) {
            childImageSharp {
                fluid(maxWidth: 1200) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        banner: file(relativePath: { in: "1031-new-banner.webp" }) {
            childImageSharp {
                fluid(maxWidth: 1200) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`;
